<template>
  <div class="grid row">
    <div class="flex xs12">
      <va-card title="Редагування Redirect" class="fill-height">
        <div>
          <div class="row">
            <div class="flex xs12">
              <va-tabs v-model="tabValue" style="width: 100%; min-width: 250px; margin-bottom: 25px;">
                <va-tab
                  v-for="title in tabTitles"
                  :key="title"
                >
                  {{title}}
                </va-tab>
              </va-tabs>
              <div class="flex xs12" v-if="tabValue === 0">
                <va-input
                  label="From *"
                  v-model="from"
                  @input="delete errors.from"
                  :error="!!errors.from"
                  :error-messages="errors.from"
                />
                <va-input
                  label="To *"
                  v-model="to"
                  @input="delete errors.to"
                  :error="!!errors.to"
                  :error-messages="errors.to"
                />
                <va-toggle label="Is active" small v-model="isActive"/>
                <va-button @click="submit()">Save</va-button>
              </div>
            </div>
          </div>
        </div>
      </va-card>
    </div>
  </div>
</template>

<script>
import * as axios from 'axios'
import Vue from 'vue'

export default {
  name: 'grid',
  data () {
    return {
      from: '',
      to: '',
      isActive: false,

      sign: '',

      errors: [],

      tabTitles: ['Main'],
      tabValue: 0,
    }
  },
  created () {
    this.fetch()
    const generateRandomString = (length = 6) => Math.random().toString(20).substr(2, length)
    this.sign = generateRandomString(32)
  },
  computed: {
    computedStyle () {
      return {
        backgroundColor: this.$themes.primary,
      }
    },
    locale () {
      return Vue.i18n.locale()
    },
  },
  watch: {
    locale: function (value) {
      this.fetch()
    },
  },
  methods: {
    submit () {
      const data = {
        from: this.from,
        to: this.to,
        is_active: this.isActive ? 1 : 0,

        sign: this.sign ? this.sign : null,
      }
      if (this.$attrs.id) { // update
        axios.put(`${process.env.VUE_APP_AGRO_API_URL}/admin/site-redirect/${this.$attrs.id}?lang=${this.locale}`, data)
          .then(response => {
            this.$router.push({ name: 'agro-seo-redirects' })
            this.showToast('Success')
          })
          .catch(error => {
            if (error.response.status === 422) {
              this.errors = error.response.data.errors
            }
            console.log(error)
            this.showToast('Error')
          })
      } else {
        axios.post(`${process.env.VUE_APP_AGRO_API_URL}/admin/site-redirect?lang=${this.locale}`, data)
          .then(response => {
            this.$router.push({ name: 'agro-seo-redirects' })
            this.showToast('Success')
          })
          .catch(error => {
            if (error.response.status === 422) {
              this.errors = error.response.data.errors
            }
            console.log(error)
            this.showToast('Error')
          })
      }
    },
    fetch () {
      if (this.$attrs.id) {
        axios.get(`${process.env.VUE_APP_AGRO_API_URL}/admin/site-redirect/${this.$attrs.id}?lang=${this.locale}`)
          .then(response => {
            console.log(response.data)
            this.from = response.data.from
            this.to = response.data.to
            this.isActive = !!response.data.is_active
          })
          .catch(error => {
            console.log(error)
            this.showToast('Error')
          })
      }
    },
  },
}
</script>

<style lang="scss">
.grid {
  &__container {
    min-height: 3rem;
    color: $white;
    border-radius: 0.5rem;
  }
}

.va-file-upload-gallery-item {
  flex-basis: unset;
  max-width: 400px;
  width: unset;
}
</style>
